export const stepsData: { [key: string]: { title: string; tooltip?: string } } =
  {
    '0': {
      title: 'Company Information',
      tooltip:
        "To match you with the best grants, we need some information about the company. At a minimum, please provide the URL of the company's website. In the fields below, you can add additional information to enhance your search. Your responses are confidential.",
    },
    '1': {
      title: 'Add More Company Information',
      tooltip:
        'The documents will be stored in your dashboard. You can add/edit your knowledge base as needed. This is your repository of information relevant to your proposal.',
    },
    '2': {
      title: 'Company Summary',
    },
    '3': {
      title: 'Protected Group Information',
      tooltip:
        "To help us match you with the best grants, we need to gather some information about the company's owners/founders. Many grants are designed to support specific groups, and knowing more about the company's owners will allow us to find more eligible opportunities.",
    },
    '4': {
      title: 'Project Information',
    },
    '5': {
      title: 'Preferred Grants',
      tooltip:
        'Tell us more about the types of grants you are interested in or any specific instructions to help us find the best matches for you.',
    },
    '6': {
      title: 'Select the Most Relevant Grant',
    },
    '7': {
      title: 'Selected Grant Alignment',
    },
    '8': {
      title: 'Edit Grant Proposal',
    },
    '9': {
      title: 'Feedback',
    },
  }

export const stepsDataRedrafting: {
  [key: string]: { title: string; tooltip?: string }
} = {
  '7': {
    title: 'Upload Grant Proposal Draft',
  },
  '8': {
    title: 'Edit Grant Proposal',
  },
  '9': {
    title: 'Feedback',
  },
}
