'use client'

import { RiSparklingFill } from '@remixicon/react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAuth from '@/hooks/useAuth'

import { getAnswerDraft } from '@/service/Chatbot'
import { generateUUID, markdown } from '@/utils'
import { cn } from '@/utils/clsx'
import {
  forkGrantThread,
  getCurrentStepNumQuestions,
  getStepMessage,
  getStepQuestions,
  isLastStep,
} from '@/utils/grant'
import { enhanceCompanyInfoPrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import TextAreaWithUpload from '../TextAreaWithUpload'
import lottieSearchingAnimation from '../../../../public/lottieSearching.json'

interface AgentResponseStepProps {
  getAnswerWrapper: (
    question: string,
    streaming: boolean,
    conversationId?: string,
    json?: boolean
  ) => Promise<void>
  loading?: boolean
  goBack: () => void
  setLoading: (loading: boolean) => void
}

const AgentResponseStep: React.FC<AgentResponseStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
  setLoading,
}) => {
  const { t } = useTranslation()
  const { questions, steps, setCurrentStep, currentStep, setSteps, mode } =
    useGrantApplicationState()
  const [numQuestions, setNumQuestions] = useState<number>(0)
  const { setSelectedConversation, mutateConversations } = useDrawerState()
  const [enhancing, setEnhancing] = useState(false)
  const answerRef = useRef<HTMLDivElement>(null)
  const additionalInfoRef = useRef<string>('')
  const stepMessage = getStepMessage(questions, steps, currentStep)
  const { user } = useAuth()

  useEffect(() => {
    if (!numQuestions && steps[currentStep]?.numQuestions) {
      setNumQuestions(steps[currentStep]?.numQuestions ?? 1)
    }
    additionalInfoRef.current = steps[currentStep]?.additionalInfo ?? ''
  }, [steps[currentStep]])

  useEffect(() => {
    if (!loading && questions[questions.length - 1]?.messages[1]?.message) {
      const newNumQuestions = getCurrentStepNumQuestions(
        questions,
        steps,
        currentStep
      )
      setNumQuestions(newNumQuestions)
      setSteps({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: newNumQuestions,
        },
      })
      additionalInfoRef.current = steps[currentStep]?.additionalInfo ?? ''
      setEnhancing(false)
    }
  }, [loading])

  const finish = async () => {
    if (steps[currentStep + 1]) {
      setCurrentStep(currentStep + 1)
    } else {
      setSteps({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          additionalInfo: additionalInfoRef.current,
        },
      })
      setCurrentStep(currentStep + 1)
    }
  }

  const enhance = async (values: { input: string }) => {
    if (isLastStep(currentStep, steps)) {
      setEnhancing(true)
      await getAnswerWrapper(
        enhanceCompanyInfoPrompt(
          stepMessage as string,
          steps[0],
          values?.input
        ),
        true
      )
    } else {
      await regenerate(values)
    }
  }

  const regenerate = async (values?: { input: string }) => {
    const id = generateUUID()
    setLoading(true)
    const question = enhanceCompanyInfoPrompt(
      stepMessage as string,
      steps[0],
      values?.input
    )
    const questionResult = await getAnswerDraft(
      true,
      question,
      1,
      getStepQuestions(questions, steps, currentStep),
      undefined,
      false,
      user?.email,
      id
    )
    await forkGrantThread(
      id,
      questions,
      steps,
      currentStep,
      question,
      mode,
      questionResult
    )
    mutateConversations()
    setSelectedConversation(id)
    setLoading(false)
  }

  return (
    <div className='flex h-full grow flex-col'>
      <div
        className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'
        ref={answerRef}
      >
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full min-h-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !stepMessage ? 'h-full' : ''
          )}
        >
          {!loading && stepMessage ? (
            <>
              <div className='markdown-answer mb-6 grow break-words text-left'>
                {markdown(stepMessage as string, loading)}
              </div>
              {!loading && (
                <>
                  <TextAreaWithUpload
                    inputRef={additionalInfoRef}
                    onFinish={enhance}
                    finishIcon={<RiSparklingFill className='size-5' />}
                    finishText='Enhance'
                    formErrorMessage='Please add enhancement instructions.'
                    placeholder='How would you like to enhance this information?'
                    uploadTooltip='Upload Files'
                  />
                  {!isLastStep(currentStep, steps) && (
                    <div className='mt-2 text-error'>
                      {t('thread-fork-warning')}
                    </div>
                  )}
                </>
              )}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              lottieAnimation={lottieSearchingAnimation}
              text={[
                'Analyzing web sources for relevant information...',
                'Thinking...',
                'Gathering detailed company insights...',
                'Thinking...',
                'Compiling comprehensive company details...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        changeIndicator={questions[questions.length - 1]?.messages[1]?.message}
        goBack={goBack}
        goNext={finish}
        disabledBack={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
        disabledNext={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
      />
    </div>
  )
}

export default AgentResponseStep
