'use client'

import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react'
import { Button, Form } from 'antd'
import { RefObject } from 'react'

import ScrollToBottom from '../Chatbot/ScrollToBottom'

interface ProgressButtonsProps {
  additionalButtons?: React.ReactNode
  goBack: () => void
  goNext?: () => void
  nextText?: string
  disabledBack?: boolean
  disabledNext?: boolean
  onlyBackButton?: boolean
  containerRef?: RefObject<HTMLDivElement>
  changeIndicator?: any
  regenerate?: () => void
}

const ProgressButtons: React.FC<ProgressButtonsProps> = ({
  additionalButtons,
  goBack,
  goNext,
  nextText,
  disabledBack,
  disabledNext,
  onlyBackButton,
  containerRef,
  changeIndicator,
}) => {
  return (
    <div className='relative flex w-full flex-col'>
      {containerRef && (
        <ScrollToBottom
          containerRef={containerRef}
          changeIndicator={changeIndicator}
          classname='absolute bottom-12'
        />
      )}
      <div className='flex w-full items-center justify-between gap-2 bg-surface px-4 py-2 transition-none dark:bg-dark-surface'>
        <Button
          onClick={goBack}
          icon={<RiArrowLeftSLine className='size-5' />}
          disabled={disabledBack}
        >
          <span className='!hidden lg:!flex'>Back</span>
        </Button>
        {additionalButtons}
        {!onlyBackButton && (
          <Form.Item className='m-0'>
            <Button
              onClick={goNext}
              iconPosition='end'
              icon={<RiArrowRightSLine className='size-5' />}
              htmlType='submit'
              type='primary'
              disabled={disabledNext}
            >
              <span className='!hidden lg:!flex'>
                {nextText ? nextText : 'Next'}
              </span>
            </Button>
          </Form.Item>
        )}
      </div>
    </div>
  )
}

export default ProgressButtons
