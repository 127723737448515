import { dateFormat, datetimeFormat } from '@/branding-config'

import { sectionToString } from '..'

import { Section } from '@/types/document'

export const generateSectionPrompt = (
  instructions: string,
  sections?: Section[]
) => `RESPOND IN JSON.

<CONTEXT>
${sections?.map((s) => sectionToString(s)).join('\n')}
</CONTEXT>


TASK:
Your goal is to generate a new, long and detailed section. The resulting section text should be elaborate and descriptive.

<IMPORTANT>You must follow these specific guidelines when generating new section above all else: ${instructions}</IMPORTANT>

RESPONSE FORMAT:
Return the enhanced section in valid JSON with the following structure:
{
  "title": "Section Title",
  "text": "Expanded and very long and detailed section text in markdown, AT LEAST 5000 CHARACTERS LONG",
  "references: ["source1", "source2", ...],
  "subsections": [
    {
      "title": "Subsection Title",
      "text": "Expanded and very long and detailed subsection text in markdown, AT LEAST 5000 CHARACTERS LONG",
      "references: ["source1", "source2", ...]
    }
  ]
}


INSTRUCTIONS:
- Ensure that the text of section and subsections is very long and detailed.
- Expand on every idea, providing additional context, explanation, and examples where relevant.
- Break up the section into SUBSECTIONS where appropriate to improve readability and structure.
- Add more very detailed and specific information to the section.
- Ensure that titles are clear, concise, and descriptive. Title should be in property "title" and not be included in property "text".
- Always return section text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- Always return subsection text in valid markdown format. INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- When listing items in section text or subsection text use numbered or bulleted lists in markdown format where appropriate.
- USE IN-TEXT CITATIONS IN APA FORMAT FOR ALL FACTS, DATA, AND INFORMATION THAT IS RETRIEVED FROM CONTEXT DOCUMENTS, MESSAGE HISTORY OR WEB SEARCHES!!!

ADDITIONAL RULES:
- DO NOT SHORTEN ANY TEXT.
- Subsections are optional, but create them when it makes sense based on titles and subtitles.
- INSTEAD OF CHARACTER "\\n" ALWAYS USE AN ACTUAL NEW LINE.
- DO NOT USE DOUBLE PARENTHESES.
- DO NOT USE BACKSLASHES.
- THE TITLE OF THE SECTION AND SUBSECTION MUST BE IN PROPERTY "TITLE" AND MUST NOT BE INCLUDED IN PROPERTY "TEXT".
- FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
- FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
- references array can use markdown format for values (example: "references" : ["[Example name. (YYYY) etc.](Example link)", "Example no link source"] ). 
- references array source values should be as detailed as possible (example: "references": ["[Department of Transportation. (2025). Infrastructure for Rebuilding America (INFRA) Grant Program](link)"] is better than just "link" or "[Department of Transportation](link)"). It should include author, year, title, and link if available.
- RESPOND IN JSON.`
