'use client'

import { Form, Input } from 'antd'
import { useEffect } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import ProgressButtons from '../ProgressButtons'

interface CompanyInfoStepProps {
  goBack: () => void
}

const CompanyInfoStep: React.FC<CompanyInfoStepProps> = ({ goBack }) => {
  const { setCurrentStep, currentStep, steps, setSteps } =
    useGrantApplicationState()
  const [form] = Form.useForm()

  const finish = async (values: { [x: string]: string }) => {
    setSteps({
      ...steps,
      [currentStep]: values,
    })
    setCurrentStep(currentStep + 1)
  }

  useEffect(() => {
    const companyUrl = steps[currentStep]?.companyUrl
    if (companyUrl) {
      form.setFieldValue('companyUrl', companyUrl)
    }
    const additionalInfo = steps[currentStep]?.additionalInfo
    if (additionalInfo) {
      form.setFieldValue('additionalInfo', additionalInfo)
    }
  }, [steps[currentStep]])

  return (
    <Form
      form={form}
      onFinish={finish}
      autoComplete='off'
      requiredMark='optional'
      layout='vertical'
      initialValues={steps[currentStep]}
      className='flex flex-col'
    >
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex w-full flex-col gap-6 self-start rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          <div className='flex flex-col'>
            <p className='mb-4'>
              Enter the company URL to help us find the best grants. Add more
              details below to find additional grants.
            </p>
            <Form.Item
              label='Company URL'
              name='companyUrl'
              className='font-semibold'
              rules={[{ type: 'string', required: true }]}
            >
              <Input className='font-normal' placeholder='Company URL' />
            </Form.Item>
            <Form.Item
              label='Additional Information'
              name='additionalInfo'
              className='font-semibold'
              rules={[{ type: 'string' }]}
            >
              <Input.TextArea
                rows={4}
                className='font-normal'
                placeholder='Add additional information about the company here.'
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <ProgressButtons goBack={goBack} />
    </Form>
  )
}

export default CompanyInfoStep
