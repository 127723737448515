'use client'

import { Draggable } from '@hello-pangea/dnd'
import { RiDraggable } from '@remixicon/react'
import { Input, Tooltip } from 'antd'
import { PropsWithChildren, useEffect, useState } from 'react'

import { countWords, markdown } from '@/utils'
import { cn } from '@/utils/clsx'

import ActionButtons from './ActionButtons'
import CollapseButton from './CollapseButton'
import GraphContainer from './GraphContainer'

import { Section } from '@/types/document'

interface SectionContainerProps extends PropsWithChildren {
  section: Section
  index: number
  enhancing?: boolean
  editing?: boolean
  collapsed?: boolean
  setEnhancing: (value?: boolean) => void
  setEditing: (value?: boolean) => void
  setEditingSubsection?: (subsectionId: string, value?: boolean) => void
  setCollapsed: (value?: boolean) => void
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  section,
  index,
  enhancing,
  editing,
  collapsed,
  children,
  setEnhancing,
  setEditing,
  setEditingSubsection,
  setCollapsed,
}) => {
  const [title, setTitle] = useState<string>(section.title)
  const [text, setText] = useState<string>(section.text)

  useEffect(() => {
    setTitle(section.title)
    setText(section.text)
  }, [section])

  const handleCountWords = (section: Section) => {
    const sectionWords = countWords(`${title} ${text}`)
    const subsectionsWords = countWords(
      section.subsections?.reduce(
        (acc, subsection) =>
          acc + ' ' + subsection.title + ' ' + subsection.text,
        ''
      ) ?? ''
    )
    return sectionWords + subsectionsWords
  }

  return (
    <Draggable draggableId={section.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cn(
            'flex rounded-lg py-2 bg-surface text-left dark:bg-dark-surface text-on-surface dark:text-dark-on-surface',
            enhancing && 'opacity-50 pointer-events-none',
            snapshot.isDragging &&
              'bg-primary dark:bg-dark-primary text-on-primary dark:text-dark-on-primary'
          )}
        >
          <div {...provided.dragHandleProps}>
            <RiDraggable className='absolute mt-1 size-6' />
          </div>
          <div className='flex w-full flex-col gap-3 pl-8 text-left sm:pl-9'>
            <div className='flex items-center gap-2'>
              {editing ? (
                <Input
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              ) : (
                <div className='flex w-full items-center justify-between'>
                  <h2 className='truncate text-xl font-bold'>
                    {section.title}
                  </h2>
                  <CollapseButton
                    setCollapsed={setCollapsed}
                    collapsed={collapsed}
                  />
                </div>
              )}
            </div>

            {editing ? (
              <Input.TextArea
                autoSize={{ minRows: 5 }}
                value={text}
                className='w-full'
                onChange={(event) => setText(event.target.value)}
              />
            ) : (
              <>
                {!collapsed && (
                  <div className='markdown-answer break-words'>
                    {markdown(section.text)}
                    <div className='flex flex-col gap-4'>
                      {section.graph?.map((graph, index) => (
                        <GraphContainer
                          key={index}
                          index={index}
                          section={section}
                          graph={graph}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className='flex items-center gap-3'>
              <ActionButtons
                section={section}
                enhancing={enhancing}
                setEnhancing={setEnhancing}
                setEditing={setEditing}
                setEditingSubsection={setEditingSubsection}
                editing={editing}
                title={title}
                text={text}
                setTitle={setTitle}
                setText={setText}
              />
              <Tooltip title={`Number of words in section "${section.title}".`}>
                <div className='text-xs opacity-70'>
                  {handleCountWords(section)} words
                </div>
              </Tooltip>
            </div>
            {children}
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default SectionContainer
