import axios from 'axios'
import dayjs from 'dayjs'

import { datetimeFormat } from '@/branding-config'
import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { getAnswerDraft } from '@/service/Chatbot'

import enhance from './enhance'
import generateSection from './generateSection'
import updateGraphsOfSection from './graph'

import { User } from '@/types'
import { IQuestion } from '@/types/chatbot'
import { GrantApplicationMode } from '@/types/grants'

const draftAnswer = async (
  question: string,
  questions: IQuestion[],
  user?: User,
  selectedConversation?: string,
  json?: boolean,
  domain?: boolean
) => {
  let answer = await getAnswerDraft(
    domain === undefined ? true : domain,
    question,
    1,
    questions,
    undefined,
    false,
    user?.email,
    selectedConversation,
    undefined,
    undefined,
    json
  )
  if (!answer?.answer) {
    throw new Error('Failed to draft answer.')
  }
  answer = answer.answer
    .replace(/<!>metadata:.*?<!>/g, '')
    .replace(/<function>.*?<\/function>/g, '')
  return answer
}

const createTmpMessage = (question: string, agentId: string): IQuestion => {
  return {
    question: question,
    messages: [
      {
        role: 'user',
        type: 'text',
        domain: true,
        message: question,
        agent: agentId,
        drafts: [],
        documents: [],
        titles: [],
        googleDriveUrls: [],
        timestamp: dayjs().format(datetimeFormat),
        isCommand: false,
        feedbackScore: 0,
      },
    ],
  }
}

const getStepQuestions = (
  questions: IQuestion[],
  steps: { [key: string]: any },
  currentStep: number
) => {
  let questionIndex = 0
  for (let i = 10; i > currentStep; i--) {
    questionIndex += steps[i]?.numQuestions ?? 0
  }
  return questionIndex === 0 ? questions : questions.slice(0, -questionIndex)
}

const getStepMessage = (
  questions: IQuestion[],
  steps: { [key: string]: any },
  currentStep: number
) => {
  const stepQuestions = getStepQuestions(questions, steps, currentStep)
  return stepQuestions[stepQuestions.length - 1]?.messages[1]?.message
}

const getCurrentStepNumQuestions = (
  questions: IQuestion[],
  steps: { [key: string]: any },
  currentStep: number
) => {
  let numQuestions = 0
  for (let i = 0; i < Object.keys(steps).length; i++) {
    if (i === currentStep) {
      continue
    }
    numQuestions += steps[i]?.numQuestions ?? 0
  }

  return questions.length - numQuestions
}

const forkGrantThread = async (
  newConversationId: string,
  questions: IQuestion[],
  steps: { [key: string]: any },
  currentStep: number,
  prompt: string,
  mode?: GrantApplicationMode,
  questionResult?: any,
  question?: IQuestion
) => {
  const tmpSteps: { [key: string]: any } = {}
  for (let i = 0; i <= currentStep; i++) {
    tmpSteps[i] = steps[i]
  }
  tmpSteps[currentStep] = {
    ...tmpSteps[currentStep],
    numQuestions: (tmpSteps[currentStep]?.numQuestions ?? 0) + 1,
  }

  const tmpQuestion: IQuestion = question ?? {
    question: prompt,
    messages: [
      {
        role: 'user',
        type: 'text',
        domain: true,
        message: prompt,
        agent: questionResult.agent,
        drafts: [],
        documents: [],
        titles: [],
        googleDriveUrls: [],
        timestamp: dayjs().format(datetimeFormat),
        isCommand: false,
        feedbackScore: 0,
      },
      {
        role: 'assistant',
        type: 'text',
        domain: true,
        message: questionResult.answer.replace(
          /<function>.*?<\/function>/g,
          ''
        ),
        agent: questionResult.agent,
        drafts: [],
        documents: questionResult.documents,
        titles: questionResult.titles,
        googleDriveUrls: [],
        timestamp: dayjs().format(datetimeFormat),
        isCommand: false,
        feedbackScore: 0,
      },
    ],
  }

  const tmpQuestions = getStepQuestions(questions, steps, currentStep)
  if (questionResult) {
    tmpQuestions.push(tmpQuestion)
  }

  const config = {
    method: 'post',
    withCredentials: true,
    ...configHeader,
    data: JSON.stringify({
      id: newConversationId,
      data: JSON.stringify({
        currentStep,
        steps: tmpSteps,
        mode,
        questions: tmpQuestions,
      }),
    }),
  }

  try {
    const data = await axios(`${API_URL}/grant-thread`, config)
    return data.data.id as string
  } catch (error) {
    console.error('Error storing thread to db', error)
    return undefined
  }
}

const isLastStep = (currentStep: number, steps: { [key: string]: any }) => {
  return !steps[currentStep + 1]
}

export {
  createTmpMessage,
  draftAnswer,
  enhance,
  forkGrantThread,
  generateSection,
  getCurrentStepNumQuestions,
  getStepMessage,
  getStepQuestions,
  isLastStep,
  updateGraphsOfSection,
}
