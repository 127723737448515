'use client'

import { Draggable } from '@hello-pangea/dnd'
import { RiDraggable } from '@remixicon/react'
import { Input } from 'antd'
import { useEffect, useState } from 'react'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'

import ActionButtons from './ActionButtons'
import CollapseButton from './CollapseButton'
import GraphContainer from './GraphContainer'

import { Section, Subsection } from '@/types/document'

interface SubsectionContainerProps {
  section: Section
  subsection: Subsection
  index: number
  enhancing?: boolean
  editing?: boolean
  collapsed?: boolean
  setEnhancing: (value?: boolean) => void
  setEditing: (value?: boolean) => void
  setCollapsed: (value?: boolean) => void
}

const SubsectionContainer: React.FC<SubsectionContainerProps> = ({
  section,
  subsection,
  index,
  enhancing,
  editing,
  collapsed,
  setEnhancing,
  setEditing,
  setCollapsed,
}) => {
  const [title, setTitle] = useState<string>(subsection.title)
  const [text, setText] = useState<string>(subsection.text)

  useEffect(() => {
    setTitle(subsection.title)
    setText(subsection.text)
  }, [subsection])

  return (
    <Draggable draggableId={subsection.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cn(
            'flex rounded-lg py-2 bg-surface text-left dark:bg-dark-surface text-on-surface dark:text-dark-on-surface',
            enhancing && 'opacity-50 pointer-events-none',
            snapshot.isDragging &&
              'bg-primary dark:bg-dark-primary text-on-primary dark:text-dark-on-primary'
          )}
        >
          <div {...provided.dragHandleProps}>
            <RiDraggable className='absolute mt-1 size-6' />
          </div>
          <div className='flex w-full flex-col gap-3 pl-8 text-left sm:pl-9'>
            <div className='flex w-full flex-col gap-2'>
              <div className='flex items-center gap-2'>
                {editing ? (
                  <Input
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                  />
                ) : (
                  <div className='flex w-full items-center justify-between'>
                    <h2 className='truncate text-lg font-semibold'>
                      {subsection.title}
                    </h2>
                    <CollapseButton
                      setCollapsed={setCollapsed}
                      collapsed={collapsed}
                    />
                  </div>
                )}
              </div>
              {editing ? (
                <Input.TextArea
                  autoSize={{
                    minRows: 5,
                  }}
                  value={text}
                  className='w-full'
                  onChange={(event) => setText(event.target.value)}
                />
              ) : (
                <>
                  {!collapsed && (
                    <div className='markdown-answer break-words'>
                      {markdown(subsection.text)}
                      <div className='flex flex-col gap-4'>
                        {subsection.graph?.map((graph, index) => (
                          <GraphContainer
                            key={index}
                            index={index}
                            section={section}
                            subsection={subsection}
                            isSubsection
                            graph={graph}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
              <ActionButtons
                section={subsection}
                enhancing={enhancing}
                setEnhancing={setEnhancing}
                setEditing={setEditing}
                editing={editing}
                title={title}
                text={text}
                setTitle={setTitle}
                setText={setText}
                isSubsection
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default SubsectionContainer
