'use client'

import { message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { jsonrepair } from 'jsonrepair'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAgents from '@/hooks/useAgents'
import useConversation from '@/hooks/useConversation'

import DOC from '@/assets/icons/doc'
import PDF from '@/assets/icons/pdf'

import { configFileHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { generateUUID } from '@/utils'

import ProgressButtons from '../ProgressButtons'

import { Section } from '@/types/document'

interface UploadGrantStepProps {
  mutateConversations?: () => void
  setLoading: (loading: boolean) => void
  goBack: () => void
}

const UploadGrantStep: React.FC<UploadGrantStepProps> = ({
  mutateConversations,
  setLoading,
  goBack,
}) => {
  const { setCurrentStep, currentStep, steps, setSteps, setSections } =
    useGrantApplicationState()
  const { selectedConversation } = useDrawerState()
  const { selectedAgent } = useAgents()
  const { mutate: mutateConversation } = useConversation(selectedConversation)

  const onChange = async (info: {
    file: {
      name?: string
      status?: string
      originFileObj?: Blob
      filename?: string
    }
  }) => {
    const { status, originFileObj, filename } = info.file
    if (status === 'done') {
      setLoading(true)
      setCurrentStep(currentStep + 1)

      try {
        if (originFileObj) {
          const formData = new FormData()
          formData.append('file', originFileObj, filename)
          formData.append('conversationId', selectedConversation as string)
          formData.append('agentId', selectedAgent.id as string)

          const res = await axios({
            url: `${API_URL}/google-doc/extract-text`,
            method: 'post',
            withCredentials: true,
            data: formData,
            ...configFileHeader,
          })
          mutateConversation()
          mutateConversations?.()
          const text = res.data.sections

          let json: { [key: string]: Section }
          if (typeof text === 'string') {
            json = JSON.parse(
              jsonrepair(text.replaceAll('```json', '').replaceAll('```', ''))
            )
          } else {
            json = text
          }
          const newSections = Object.keys(json).map((key: string) => ({
            id: generateUUID(),
            title: json[key]?.title ?? '',
            text: json[key]?.text ?? '',
            subsections:
              json[key]?.subsections?.map(
                (subsection: { title: string; text: string }) => ({
                  id: generateUUID(),
                  title: subsection.title,
                  text: subsection.text,
                })
              ) ?? [],
          }))
          setSections({ sections: newSections, history: [], historyIndex: 0 })
          setSteps({
            ...steps,
            [currentStep + 1]: {
              ...steps[currentStep + 1],
              sections: newSections,
              numQuestions: 1,
            },
          })
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        message.error('Failed to extract text from the uploaded file')
        setLoading(false)
        setCurrentStep(7)
      }
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  return (
    <>
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          <Dragger
            onChange={onChange}
            maxCount={1}
            accept='.docx,.pdf'
            showUploadList={false}
            className='grid h-full grow'
          >
            <div className='flex w-full flex-col items-center justify-center gap-2 px-5 py-14'>
              <div className='flex gap-2'>
                <PDF className='size-10 md:size-16' />
                <DOC className='size-10 md:size-16' />
              </div>
              <p className='text-base'>
                Upload Grant Application You Want to Refine
              </p>
              <p className='text-xs'>
                Click or drag a file to this area to upload
              </p>
            </div>
          </Dragger>
        </div>
      </div>
      <ProgressButtons goBack={goBack} onlyBackButton />
    </>
  )
}

export default UploadGrantStep
