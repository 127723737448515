export const feedbackPrompt = (values: { [x: string]: string }) =>
  `These are my replies to the 5 questions you asked me:
1. How satisfied are you with the SmartGrants.ai assistance you received?
${values.feedback1}

2. How would you rate the quality of the proposal SmartGrants.ai produced?
${values.feedback2}

3. Compared to normal non-assisted grant writing, how would you rate the experience of using SmartGrants.ai for writing a grant proposal?
${values.feedback3}

4. Compared to normal non-assisted grant writing, how would you rate the experience of using SmartGrants.ai for writing a grant proposal?
${values.feedback4 ?? '-'}

5. What other questions should be asked of users of SmartGrants.ai during onboarding to providing even better results?
${values.feedback5 ?? '-'}      

Respond just with: Thank you for your feedback. We will take it into account.`
