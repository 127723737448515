'use client'

import { RiRefreshLine } from '@remixicon/react'
import { Button, Grid } from 'antd'
import { useState } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import ProgressButtons from '../ProgressButtons'

interface GoogleDocsViewerProps {
  documentUrl?: string
  setDocumentUrl: (documentUrl?: string) => void
  setGeneratingDocument: (generatingDocument: boolean) => void
  getAnswerWrapper?: (question: string, streaming: boolean) => Promise<void>
}

const GoogleDocsViewer: React.FC<GoogleDocsViewerProps> = ({
  documentUrl,
  setDocumentUrl,
  setGeneratingDocument,
}) => {
  const { setCurrentStep, currentStep, steps, setSteps } =
    useGrantApplicationState()
  const [iframeKey, setIframeKey] = useState(false)
  const screens = Grid.useBreakpoint()

  const finish = async () => {
    setSteps({
      ...steps,
      [currentStep + 1]: {
        numQuestions: 1,
      },
    })
    setCurrentStep(currentStep + 1)
  }

  return (
    <>
      <div className='m-auto size-full h-[100vh-110px] sm:max-w-[100em]'>
        <iframe
          key={iframeKey.toString()}
          src={documentUrl}
          className='m-auto size-full max-w-[100rem]'
        />
      </div>
      <ProgressButtons
        additionalButtons={
          <Button
            icon={!screens.lg && <RiRefreshLine className='size-5' />}
            onClick={() => setIframeKey(!iframeKey)}
          >
            {screens.lg && 'Refresh editor'}
          </Button>
        }
        goBack={() => {
          setDocumentUrl(undefined)
          setGeneratingDocument(false)
          setSteps({
            ...steps,
            [currentStep]: {
              ...steps[currentStep],
              documentUrl: undefined,
            },
          })
        }}
        goNext={finish}
      />
    </>
  )
}

export default GoogleDocsViewer
