'use client'

import { Checkbox, Form, Input } from 'antd'
import { useEffect, useState } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import ProgressButtons from '../ProgressButtons'

const options = [
  "I'm applying as a minority (e.g., racial or ethnic minority)",
  "I'm applying as a veteran",
  "I'm applying as a person with disability",
  "I'm applying as female",
  "I'm applying as non-binary or gender non-conforming",
  "I'm applying as LGBTQ+",
  "I'm applying as an immigrant or refugee",
  "I'm applying as an indigenous person",
  "I'm applying as another underrepresented or disadvantaged group",
  'None of the above',
]

interface ProtectedGroupStepProps {
  goBack: () => void
}

const ProtectedGroupStep: React.FC<ProtectedGroupStepProps> = ({ goBack }) => {
  const [form] = Form.useForm()
  const { currentStep, setCurrentStep, steps, setSteps } =
    useGrantApplicationState()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const finish = async (values: { [x: string]: string }) => {
    setSteps({
      ...steps,
      [currentStep]: values,
    })
    setCurrentStep(currentStep + 1)
  }

  const handleChange = (checkedValues: string[]) => {
    const added = checkedValues.length > selectedOptions.length
    if (added) {
      const addedOption = checkedValues.find(
        (option) => !selectedOptions.includes(option)
      )
      if (addedOption === 'None of the above') {
        setSelectedOptions(['None of the above'])
        form.setFieldsValue({ identity: ['None of the above'] })
      } else {
        const newValues = checkedValues.filter(
          (option) => option !== 'None of the above'
        )
        setSelectedOptions(newValues)
        form.setFieldsValue({ identity: newValues })
      }
    } else {
      const newValues = checkedValues.filter(
        (option) => option !== 'None of the above'
      )
      setSelectedOptions(newValues)
      form.setFieldsValue({ identity: newValues })
    }
  }

  useEffect(() => {
    if (steps[currentStep]?.identity) {
      setSelectedOptions(steps[currentStep].identity)
    }
    const additionalInfo = steps[currentStep]?.additionalInfo
    if (additionalInfo) {
      form.setFieldValue('additionalInfo', additionalInfo)
    }
  }, [steps[currentStep]])

  return (
    <Form
      form={form}
      onFinish={finish}
      autoComplete='off'
      requiredMark='optional'
      layout='vertical'
      initialValues={steps[currentStep]}
    >
      <div className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'>
        <div className='m-auto flex w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
          <div className='flex flex-col font-semibold'>
            <p className='mb-4'>
              Please indicate if any of the following apply to the company
              (select all that apply).
            </p>
            <Form.Item name='identity' className='w-full'>
              <Checkbox.Group
                className='flex flex-col gap-1 font-normal'
                options={options}
                value={selectedOptions}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item
              label='Additional Information'
              name='additionalInfo'
              rules={[{ type: 'string' }]}
            >
              <Input.TextArea
                className='font-normal'
                rows={4}
                placeholder='Share any additional details about the company. Examples include previous grants applied for with preference for protected groups.'
              />
            </Form.Item>
            <div className='font-normal'>
              <h4 className='font-semibold'>
                Privacy and Confidentiality Note:
              </h4>
              <p>
                Your responses will be used solely to match you with grants you
                may be eligible for. We take your privacy seriously and will not
                share your information with any third parties without your
                consent.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ProgressButtons
        goBack={goBack}
        disabledNext={selectedOptions.length === 0}
      />
    </Form>
  )
}

export default ProtectedGroupStep
