'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Popover, Radio, Space, Tooltip } from 'antd'
import { useRef, useState } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import { sectionToString } from '@/utils'
import { enhance } from '@/utils/grant'

import { Section, Subsection } from '@/types/document'
import { EnhanceType } from '@/types/grants'

const ENHANCEMENT_CHAR_LIMIT = 20000

interface EnhanceButtonProps {
  section: Section | Subsection
  enhancing?: boolean
  setEnhancing: (value?: boolean) => void
}

const EnhanceButton: React.FC<EnhanceButtonProps> = ({
  section,
  enhancing,
  setEnhancing,
}) => {
  const { questions, sections, setSections, setSteps } =
    useGrantApplicationState()
  const { selectedConversation } = useDrawerState()
  const { user } = useAuth()
  const { selectedAgent } = useAgents()
  const [open, setOpen] = useState(false)
  const [enhanceInstructions, setEnhanceInstructions] = useState('')
  const [type, setType] = useState<EnhanceType>(EnhanceType.LONGER)
  const textareaRef = useRef<any>(null)

  const handleEnhance = async () => {
    setOpen(false)
    setEnhancing(true)
    await enhance(
      setSections,
      setSteps,
      questions,
      selectedAgent.id,
      section,
      type,
      selectedConversation,
      user,
      sections,
      enhanceInstructions,
      true,
      true
    )
    setEnhancing(false)
    setEnhanceInstructions('')
    setType(EnhanceType.LONGER)
  }

  return (
    <div className='flex gap-2'>
      <Popover
        trigger='click'
        overlayClassName='w-full max-w-md'
        open={open}
        onOpenChange={setOpen}
        content={
          <Form
            autoComplete='off'
            className='flex w-full flex-col items-end'
            layout='vertical'
            initialValues={{ type: 'longer' }}
          >
            <Form.Item name='type' className='w-full'>
              <Radio.Group
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <Space direction='vertical'>
                  <Radio value={EnhanceType.LONGER}>
                    Longer With More Details
                  </Radio>
                  <Radio value={EnhanceType.SHORTER}>
                    Shorter and More Concise
                  </Radio>
                  <Radio value={EnhanceType.DATA}>
                    Gather More Data From the Web
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name='additionalInfo'
              rules={[{ type: 'string' }]}
              className='w-full'
            >
              <Input.TextArea
                ref={textareaRef}
                value={enhanceInstructions}
                size='small'
                className='max-w-64 origin-left overflow-hidden transition-all'
                placeholder='Optional: provide specific instructions to enhance this section'
                onChange={(e) => setEnhanceInstructions(e.target.value)}
                rows={4}
              />
            </Form.Item>
            <Form.Item className='m-0'>
              <Button
                className='flex items-center'
                htmlType='submit'
                type='primary'
                onClick={handleEnhance}
                icon={<RiSparklingFill className='size-5' />}
              >
                Enhance
              </Button>
            </Form.Item>
          </Form>
        }
      >
        <Tooltip
          title={
            JSON.stringify(section).length > ENHANCEMENT_CHAR_LIMIT
              ? ''
              : 'Enhance'
          }
        >
          <Button
            className='w-fit'
            icon={<RiSparklingFill className='size-5' />}
            loading={enhancing}
            disabled={sectionToString(section).length > ENHANCEMENT_CHAR_LIMIT}
            type='text'
          >
            {enhancing ? (
              <span className='!hidden sm:!block'>Enhancing...</span>
            ) : null}
          </Button>
        </Tooltip>
      </Popover>
    </div>
  )
}

export default EnhanceButton
