'use client'

import { RiArrowDownSLine, RiArrowUpSLine } from '@remixicon/react'
import { Button, Checkbox } from 'antd'
import Link from 'next/link'
import { useState } from 'react'

import { cn } from '@/utils/clsx'

interface SelectGrantCardProps {
  index: number
  name?: string
  funder?: string
  email?: string
  url?: string
  geography?: string
  type?: string
  range?: string
  alignment?: string
  eligibility?: string
  deadline?: string
  selectedGrant?: number
  setSelectedGrant: (selectedGrant?: number) => void
}

const SelectGrantCard: React.FC<SelectGrantCardProps> = ({
  index,
  name,
  funder,
  email,
  url,
  geography,
  type,
  range,
  alignment,
  eligibility,
  deadline,
  selectedGrant,
  setSelectedGrant,
}) => {
  const [viewMore, setViewMore] = useState(false)

  return (
    <div
      className={cn(
        'p-4 bg-primary/5 dark:bg-dark-primary/5 rounded-lg hover:bg-primary/20 cursor-pointer',
        index === selectedGrant
          ? 'bg-primary/40 dark:bg-dark-primary/40 hover:bg-primary/40'
          : ''
      )}
      onClick={() =>
        selectedGrant === index
          ? setSelectedGrant(undefined)
          : setSelectedGrant(index)
      }
    >
      <div className='flex flex-col gap-4'>
        <div className='flex items-center gap-2 text-lg font-bold text-primary sm:text-xl'>
          <Checkbox checked={selectedGrant === index} />
          {name}
        </div>
        <div className='grid grid-cols-2 gap-x-2 gap-y-4'>
          <div className='flex flex-col'>
            <div className='text-base font-semibold'>Grant Range</div>
            <div className='text-sm sm:text-base'>
              {!range || range === '' ? 'N/A' : range}
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='text-base font-semibold'>Deadline</div>
            <div className='text-sm sm:text-base'>
              {!deadline || deadline === '' ? 'N/A' : deadline}
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='text-base font-semibold'>Funder</div>
            <div className='text-sm sm:text-base'>
              {!funder || funder === '' ? 'N/A' : funder}
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='text-base font-semibold'>Email</div>
            <div className='text-sm sm:text-base'>
              {!email || email === '' ? 'N/A' : email}
            </div>
          </div>
          {viewMore && (
            <>
              {url && (
                <div className='flex flex-col'>
                  <div className='text-base font-semibold'>URL</div>
                  <div className='text-sm sm:text-base'>
                    <Link
                      href={url}
                      target='_blank'
                      rel='noreferrer'
                      className='!font-normal'
                    >
                      {url}
                    </Link>
                  </div>
                </div>
              )}
              <div className='flex flex-col'>
                <div className='text-base font-semibold'>Geographic Scope</div>
                <div className='text-sm sm:text-base'>
                  {!geography || geography === '' ? 'N/A' : geography}
                </div>
              </div>
              <div className='flex flex-col'>
                <div className='text-base font-semibold'>Organization Type</div>
                <div className='text-sm sm:text-base'>
                  {!type || type === '' ? 'N/A' : type}
                </div>
              </div>
              <div className='flex flex-col'>
                <div className='text-base font-semibold'>Alignment</div>
                <div className='text-sm sm:text-base'>
                  {!alignment || alignment === '' ? 'N/A' : alignment}
                </div>
              </div>
              <div className='flex flex-col'>
                <div className='text-base font-semibold'>
                  Eligibility Requirements
                </div>
                <div className='text-sm sm:text-base'>
                  {!eligibility || eligibility === '' ? 'N/A' : eligibility}
                </div>
              </div>
            </>
          )}
        </div>
        <Button
          type='link'
          onClick={(e) => {
            e.stopPropagation()
            setViewMore(!viewMore)
          }}
          iconPosition='end'
          icon={
            viewMore ? (
              <RiArrowUpSLine className='size-5' />
            ) : (
              <RiArrowDownSLine className='size-5' />
            )
          }
          className='self-start !border-0 !p-0 text-primary/70 hover:!text-primary dark:text-dark-primary/70 dark:hover:!text-dark-primary'
        >
          {viewMore ? 'Show Less' : 'Show More'}
        </Button>
      </div>
    </div>
  )
}

export default SelectGrantCard
